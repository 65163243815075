import React from 'react';

import moment from 'moment';
import config from '../config';
import api from '../api';

const getTitle = (entityData, entityName, propName, regularPlural = true, action = 'delete') => {
  const firstResourceName = entityData[0]?.[propName] || '';
  const remaining = entityData.length - 1;

  let titleText = '';

  if (!remaining) {
    titleText = `${firstResourceName || `this ${entityName}`}?`;
  } else if (firstResourceName) {
    // eslint-disable-next-line no-nested-ternary
    titleText = `${firstResourceName} and ${remaining} other ${entityName}${remaining > 1 ? regularPlural ? 's' : 'es' : ''}?`;
  } else {
    titleText = `${entityData.length} ${entityName}${regularPlural ? 's' : 'es'}?`;
  }

  let returnText = '';
  if (action !== 'delete') {
    returnText = `Do you want to tag ${titleText}`;
  } else {
    returnText = `Did you mean to delete ${titleText}`;
  }

  return returnText;
};

const deleteModalText = (customDescription) => (
  <>
    {customDescription}
    <br />
    <br />
    If you want to complete this action, type the word
    ‘delete’ in the box below and then click the pink Delete button.
  </>
);

const frontendUrl = (user = null) => {
  const customDomain = user?.customerTheme?.customDomain;
  const brightAdminEmail = user?.email ? user.email.startsWith('bright@') : false;

  if (config.application !== 'bright' && !brightAdminEmail && customDomain) {
    return `https://${customDomain}`;
  }

  switch (config.application) {
    case 'bright-prod': return 'https://app.learnwithbright.com';
    case 'bright-dev': return 'https://app-dev.learnwithbright.com';
    case 'bright-dev-v2': return 'https://app-dev-v2.learnwithbright.com';
    case 'bright-preprod': return 'https://app-preprod.learnwithbright.com';
    case 'bright-academy': return 'https://learnwithbright.academy';
    case 'bright-academy-preprod': return 'https://app-preprod.learnwithbright.academy';
    case 'bright': return 'http://localhost:3001';
    default: return 'https://app.learnwithbright.com';
  }
};

const findNextTeams = async (selectedTeams, currentTierTeams, newTierTeamsValue, tierId) => {
  const lastSelectedTeam = [...selectedTeams].pop();
  let newTierTeams = newTierTeamsValue;

  const isTeamFound = currentTierTeams
    .filter(({ parent_id: parentId }) => parentId === lastSelectedTeam.id);

  if (!isTeamFound.length) {
    const queryParams = {
      id: lastSelectedTeam.id,
      tierId,
      getMembersInfo: false,
    };
    const insertPaginationParams = false;
    const { data: { teams } } = await api.getAllTeamsListing(queryParams, insertPaginationParams);

    newTierTeams = [...teams];

    if (currentTierTeams?.length) {
      newTierTeams = [...teams, ...currentTierTeams];
    }
  } else {
    const presentTeamsId = selectedTeams.map(({ id }) => id);
    newTierTeams = currentTierTeams
      .filter(({ parent_id: parentId }) => presentTeamsId.includes(parentId));
  }

  return newTierTeams;
};

const handleTeamChange = (
  setFilters,
  userManagementStore,
  setIsFiltersLoading,
) => async (selectedTeams, tierId) => {
  setIsFiltersLoading(true);

  const {
    formOptions: {
      tierTwoTeams = [],
      tierThreeTeams = [],
      tierFourTeams = [],
    },
  } = userManagementStore;

  let newTierTwoTeams = [];
  let newTierThreeTeams = [];
  let newTierFourTeams = [];

  switch (tierId) {
    case 1:
      if (selectedTeams.length) {
        newTierTwoTeams = await findNextTeams(selectedTeams, tierTwoTeams, newTierTwoTeams, 1);
      }

      setFilters((prev) => ({
        ...prev,
        selectedTierOneTeams: selectedTeams,
        selectedTierTwoTeams: [],
        selectedTierThreeTeams: [],
        selectedTierFourTeams: [],
      }));
      break;
    case 2:
      newTierTwoTeams = [...tierTwoTeams];

      if (!selectedTeams.length) {
        setFilters((prevState) => ({
          ...prevState,
          selectedTierTwoTeams: [],
          selectedTierThreeTeams: [],
          selectedTierFourTeams: [],
        }));
      } else {
        newTierThreeTeams = await findNextTeams(
          selectedTeams,
          tierThreeTeams,
          newTierThreeTeams,
          2,
        );

        setFilters((prevState) => ({
          ...prevState,
          selectedTierTwoTeams: selectedTeams,
          selectedTierThreeTeams: [],
          selectedTierFourTeams: [],
        }));
      }
      break;
    case 3:
      newTierTwoTeams = [...tierTwoTeams];
      newTierThreeTeams = [...tierThreeTeams];

      if (!selectedTeams.length) {
        setFilters((prevState) => ({
          ...prevState,
          selectedTierThreeTeams: [],
          selectedTierFourTeams: [],
        }));
      } else {
        newTierFourTeams = await findNextTeams(
          selectedTeams,
          tierFourTeams,
          newTierFourTeams,
          3,
        );

        setFilters((prevState) => ({
          ...prevState,
          selectedTierThreeTeams: selectedTeams,
          selectedTierFourTeams: [],
        }));
      }
      break;
    case 4:
      newTierTwoTeams = [...tierTwoTeams];
      newTierThreeTeams = [...tierThreeTeams];
      newTierFourTeams = [...tierFourTeams];

      setFilters((prevState) => ({
        ...prevState,
        selectedTierFourTeams: selectedTeams,
      }));

      break;
    default:
      break;
  }

  await userManagementStore.getTierTeams({
    tierTwoTeams: newTierTwoTeams,
    tierThreeTeams: newTierThreeTeams,
    tierFourTeams: newTierFourTeams,
  });

  setIsFiltersLoading(false);
};

function convertSecondsToMMSS(seconds) {
  const duration = moment.duration(seconds, 'seconds');
  const minutes = duration.minutes();
  const secondsRemaining = duration.seconds();

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = secondsRemaining < 10 ? `0${secondsRemaining}` : secondsRemaining;

  return `${formattedMinutes}:${formattedSeconds}`;
}

const turnTextPixelToNumber = (text) => {
  if (typeof text === 'number') return text;

  if (text.includes('px')) {
    return parseInt(text.replace('px', ''), 10);
  }

  return text;
};

export {
  getTitle,
  frontendUrl,
  handleTeamChange,
  convertSecondsToMMSS,
  deleteModalText,
  turnTextPixelToNumber,
};
